/**
 * 请求地址统一处理／组装
 * @param {*} urlName 接口url名称
 */
 export default function (actionName,type) {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/siteapi/]前缀做代理拦截!
  if(type === 'admin'){
    return actionName
  }else{
    return actionName
    // return 'http://admin.parceljet.com/psj-cloud-app' + actionName
    // return (process.env.NODE_ENV !== 'development' ? 'http://admin.parceljet.com/psj-cloud-app' : '/psj-cloud-app' ) + actionName
  }
}
