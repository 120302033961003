
import request from './httpRequest'
import requestUrl from './requestUrl'
import requestParam from './requestParam'

// 登录
export function login (params) {
  return request({
    url: requestUrl('/psj-cloud-app/login/login_v2'),
    method: 'post',
    data: requestParam(params, 'post', false, 'json'),
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
}

// 退出
export function logout () {
  return request({
    url: requestUrl('/login/logout'),
    method: 'post',
    data: requestParam()
  })
}

// 获取jsessionId
export function getjsessionId () {
  return request({
    url: requestUrl(`/psj-cloud-app/login/sessionId`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 获取首页信息
export function sitehome (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/siteapi/sitehome/method=com.psj.cloud.site.home?siteCode=${data}`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 获取站点信息
export function sitenodeInfo (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/siteapi/sitenode/method=com.psj.cloud.site.node.info?siteCode=${data}`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 获取关于我们
export function siteabout (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/siteapi/siteabout/method=com.psj.cloud.site.about?siteCode=${data}`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 获取主营业务
export function sitebusiness (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/siteapi/sitebusiness/method=com.psj.cloud.site.business.info?${data}`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 新闻资讯列表
export function sitenewsList (data) {
  return request({
    url: requestUrl('/psj-cloud-app/siteapi/sitenews/method=com.psj.cloud.site.news.list'),
    method: 'post',
    data: requestParam(data)
  })
}

// 获取新闻资讯详情
export function sitenewsInfo (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/siteapi/sitenews/method=com.psj.cloud.site.news.info?id=${data}`),
    method: 'get',
    // params: requestParam(params, 'get')
  })
}

// 问答信息列表
export function sitequestionanswerList (data) {
  return request({
    url: requestUrl('/psj-cloud-app/siteapi/sitequestionanswer/method=com.psj.cloud.site.ques.list'),
    method: 'post',
    data: requestParam(data)
  })
}

// 新增问答信息
export function sitequestionanswerSave (data) {
  return request({
    url: requestUrl('/siteapi/sitequestionanswer/method=com.psj.cloud.site.ques.save'),
    method: 'post',
    data: requestParam(data)
  })
}

// 新增留言
export function siteleavemsg (data) {
  return request({
    url: requestUrl('/psj-cloud-app/siteapi/siteleavemsg/method=com.psj.cloud.site.msg.save'),
    method: 'post',
    data: requestParam(data)
  })
}

// 打卡
export function clockIn (data) {
  return request({
    url: requestUrl('/psj-cloud-app/api/method=com.psj.cloud.web.modules.site.api.ciRecordApi.clockIn'),
    method: 'post',
    data: requestParam(data)
  })
}

// 获取投诉配置列表
export function getComplaintConfigList (data) {
  return request({
    url: requestUrl(`/psj-cloud-app/crmapi/complaint/getComplaintConfigList`),
    method: 'get',
  })
}
// 新增投诉
export function createComplaintMaster (data) {
  return request({
    url: requestUrl('/psj-cloud-app/crmapi/complaint/createComplaintMaster'),
    method: 'post',
    data: requestParam(data)
  })
}

// 获取轨迹
export function trackGet (data) {
  return request({
    url: requestUrl('/psj-cloud-web/api/method=com.psj.biz.track.get'),
    method: 'post',
    data: requestParam(data)
  })
}
