import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

// 路由定义使用说明:
// 1. 代码中路由统一使用name属性跳转.
// 2. 开放path属性用做简短路由, 比如: '/a1', 访问地址: www.renren.io/#/a1
const routes = [
  { path: '/', name: "/", redirect: '/index', component: () => import(/* webpackChunkName: "Index" */ "@/views/Index.vue") },
  { path: '/index', name: 'Index', desc: '首页', meta: { id: 0 }, component: () => import(/* webpackChunkName: "Index" */ "@/views/Index") },
  { path: '/about', name: 'About', desc: '公司简介', meta: { id: 1 }, component: () => import(/* webpackChunkName: "About" */ "@/views/About") },
  { path: '/partners', name: 'Partners', desc: '合作伙伴招商', meta: { id: 2 }, component: () => import(/* webpackChunkName: "About" */ "@/views/partners") },
  { path: '/business', name: 'business', desc: '物流服务产品', meta: { id: 3 }, component: () => import(/* webpackChunkName: "business" */ "@/views/business/index.vue") },
  { path: '/information', name: 'information', desc: '服务动态及新闻', meta: { id: 4 }, component: () => import(/* webpackChunkName: "information" */ "@/views/information/index.vue") },
  { path: '/newsDetail', name: 'newsDetail', desc: '新闻详情', meta: { id: 5 }, component: () => import(/* webpackChunkName: "newsDetail" */ "@/views/newsDetail") },
  { path: '/Complaint', name: 'Complaint', desc: '投诉与建议', meta: { id: 6 }, component: () => import(/* webpackChunkName: "Complaint" */ "../views/complaint/Complaint.vue") },
  { path: '/contactUs', name: 'contactUs', desc: '联系我们', meta: { id: 7 }, component: () => import(/* webpackChunkName: "Complaint" */ "../views/contactUs.vue") },
  { path: '/customerLogin', name: 'customerLogin', desc: '客户登录', meta: { id: 8 }, component: () => import(/* webpackChunkName: "Complaint" */ "../views/customerLogin.vue") },
  { path: '/qa', name: 'Qa', desc: '问答', meta: { id: 21 }, component: () => import(/* webpackChunkName: "Qa" */ "../views/Qa/index.vue") },
  { path: '/track', name: 'Track', desc: '轨迹', meta: { id: 9 }, component: () => import(/* webpackChunkName: "Track" */ "../views/Track/track.vue") },
  { path: '/bossemail', name: 'BossEmail', desc: '总经办信箱', meta: { id: 22 }, component: () => import(/* webpackChunkName: "Track" */ "../views/BossEmail.vue") },
  { path: '/privacy', name: 'Privacy', desc: '隐私政策', meta: { id: 23 }, component: () => import(/* webpackChunkName: "Track" */ "../views/privacy.vue") },
  { path: '/demo', name: 'Demo', desc: 'Demo', meta: { id: 6 }, component: () => import(/* webpackChunkName: "Qa" */ "../views/demo/demo.vue") },
  { path: '*', redirect: { name: 'Index', component: () => import(/* webpackChunkName: "Index" */ "@/views/Index.vue"), } }
]

const createRouter = () => new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
const router = createRouter()

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
