export default {
    "zh": {
        "首页": "首页",
        "公司简介": "公司简介",
        "物流服务范围": "物流服务范围",
        "服务团队": "服务团队",
        "发展历程": "发展历程",
        "愿景使命价值观": "愿景、使命、价值观",
        "我们的优势服务": "我们的优势服务",
        "我们的服务改善": "我们的服务改善",
        "团队分布": "团队分布",
        "合作伙伴招商": "合作伙伴招商",
        "物流服务产品": "物流服务产品",
        "运营团队": "运营团队",
        "海外仓": "海外仓",
        "B2BB2C": "B2B/B2C",
        "逆向物流": "逆向物流",
        "设施分布": "设施分布",
        "投诉与建议": "投诉与建议",
        "QA": "Q&A",
        "总经办信箱": "总经办信箱",
        "联系我们": "联系我们",
        "客户登录": "客户登录",
        "请输入单号": "请输入单号",
        "跟踪": "跟 踪",
        "诚邀全球优质物流企业": '诚邀全球优质物流企业',
        "劳务派遣人才合作": '劳务派遣/人才合作',
        "请输入投诉与建议": '投诉与建议',
        "提交建议": '提交建议',
        "请输入您的姓名": '请输入您的姓名',
        "请输入您的手机号": '请输入您的手机号',
        "请输入投诉与建议信息": '请输入投诉与建议信息',
        "提交成功": '提交成功！',
        "请输入联系信息及投诉与建议信息": '请输入联系信息及投诉与建议信息',
        "请输入客户投诉号": '请输入客户投诉号',
        "请选择投诉项": '请选择投诉项',
        "提交失败请联系管理员": '提交失败，请联系管理员',
        "请输入问题": '请输入问题',
        "请输入留言信息": '请输入留言信息',
        "请输入联系信息及留言信息": '请输入联系信息及留言信息',
        "用户登录": '用户登录',
        "请输入帐号": '请输入帐号',
        "请输入密码": '请输入密码',
        "前往登录": '前往登录',
        "服务动态及新闻": '服务动态及新闻',
        "物流查询": '物流查询',
        "查询": '查询',
        "单号": '单号',
        "轨迹发生地点": '轨迹发生地点',
        "暂无数据": '暂无数据',
        "暂无轨迹数据可联系小派查询": '暂无轨迹数据,可联系小派查询',
        "合作电话值": '0757-81768696',
        "合作邮箱值": 'partner@pj-logistics.com',
        "招聘电话值": '0757-81768696',
        "招聘邮箱值": 'hr@pj-logistics.com',
        "隐私政策": '隐私政策',
        "投诉与建议文案": '本公司致力成为全球一流仓储物流服务商，坚持客户第一，回报社会，关爱员工的经营理念。遵纪守法，健康运营，如您发现本公司内部员工有贪污受贿，吃拿卡要等违纪违法行为，或者您的合法权益收到损害，请您大胆投诉举报，我们会保护您的个人信息，给您满意答复，请务必留下您的真实联系方式，提供相关证据。对无头小报告、匿名信我们将不予调查。',
        '首页标题': '为全球品牌和零售商提供订单履行和第三方物流服务',
        '首页头部介绍': 'Parceljet是一家专业的跨境物流公司，我们致力于为全球客户提供高质量、安全、高效的跨境运输服务。借助我们的全球仓储网络，我们利用先进的技术和运营专长来优化您的订单履行，而您则可以专注于发展您的业务。我们的目标是通过提供顶级的第三方物流(3PL)服务，将您的品牌传递给全世界，帮助您的业务蓬勃发展。',
        '头程': '头程',
        '空运': '空运',
        '海运': '海运',
        '报关': '报关',
        '清关': '清关',
        '海外仓储': '海外仓储',
        '尾程物流': '尾程物流',
        '卡派服务': '卡派服务',
        '头程文本': '一站式服务对接，满足跨境电商品牌客户全球销售的规划要求，国内外统一窗口对接，实时响应客户精细化和定制化需求;统一的WMS和TMS系统服务,OMS客户端直接管理,并提供持续升级的数字化物流服务体验',
        '空运文本': '制单，提货，订舱，报关，协商，监管转运，临时仓储，分拨配送，代购货运保险，协调机场地面操作，实时空运货物信息跟踪及反馈服务',
        '海运文本': '普通货物运输FCL(整柜承运)/(LCL)拼箱承运，食品冷柜货物运输，危险品运输，延伸服务为客户包船服务(散装船-限欧美)，项目定制运输方案',
        '报关文本': '专业的报关团队提供包括但不限于文件准备与提交、商品分类、税费和关税计算以及合规性咨询在内的一系列增值服务，为客户提供一站式解决方案',
        '清关文本': '专业团队提供包括但不限于预先文件准备与提交、商品分类和编码、仓储和物流安排以及合规性咨询在内的一系列增值服务，为客户提供全面服务的清关代理',
        '海外仓储文本': '16万㎡自营仓资源,欧美日多条核心出口专线,行业领先的逆向物流服务体系,海外本地团队管理,严格的仓储和配送管理标准',
        '尾程物流文本': '物流服务能力集中覆盖北美、欧盟、韩日和澳大利亚等主要消费国家,并进一步向新兴市场扩展(东南亚&非洲)',
        '卡派服务文本': '30+运输车辆,配送服务分布全球12个国家和地区,为客户提供高效、经济、安全、便捷的服务体验，帮助客户实现业务目标，加强企业在市场中的竞争力',
    },
    "en": {
        "首页": "Home",
        "公司简介": "Company",
        "物流服务范围": "Worldwide Logistic Services",
        "服务团队": "Customer teams",
        "发展历程": "History",
        "愿景使命价值观": "Our Vision, Mission, & Values",
        "我们的优势服务": "Our Industry Leading Services",
        "我们的服务改善": "Our Optimizing Services",
        "团队分布": "Teams Location",
        "合作伙伴招商": "Partners",
        "物流服务产品": "Solutions",
        "运营团队": "Support teams",
        "海外仓": "Global Warehousing",
        "B2BB2C": "B2B/B2C",
        "逆向物流": "Reverse Logistics",
        "设施分布": "Warehousing Location",
        "投诉与建议": "Customer Support",
        "QA": "Q&A",
        "总经办信箱": "General Manager Email",
        "联系我们": "Contact Us",
        "客户登录": "Log In",
        "请输入单号": "Please enter the tracking number",
        "跟踪": "tracking",
        "诚邀全球优质物流企业": 'Partner with Us',
        "劳务派遣人才合作": 'Join Parceljet',
        "请输入投诉与建议": 'Please input complaints and suggestions',
        "提交建议": 'Submit suggestions',
        "请输入您的姓名": 'Please enter your name',
        "请输入您的手机号": 'Please enter your phone number',
        "请输入投诉与建议信息": 'Please input complaint and suggestion information',
        "提交成功": 'Submitted successfully',
        "请输入联系信息及投诉与建议信息": 'Please enter contact information and complaint and suggestion information',
        "请输入客户投诉号": 'Please enter the customer complaint number',
        "请选择投诉项": 'Please select the complaint item',
        "提交失败请联系管理员": 'Submission failed, please contact the administrator',
        "请输入问题": 'Please enter the question',
        "请输入留言信息": 'Please enter the message information',
        "请输入联系信息及留言信息": 'Please enter contact information and message information',
        "用户登录": 'User Login',
        "请输入帐号": 'Please enter your account number',
        "请输入密码": 'Please enter password',
        "前往登录": 'Go to login',
        "服务动态及新闻": 'News',
        "物流查询": 'shipping tracking',
        "查询": 'query',
        "单号": 'Order No',
        "轨迹发生地点": 'The location where the track occurred.',
        "暂无数据": 'No data available.',
        "暂无轨迹数据可联系小派查询": 'No tracking data available. Please contact ParcelJet for assistance.',
        "合作电话值": '0757-81768696',
        "合作邮箱值": 'partner@pj-logistics.com',
        "招聘电话值": '0757-81768696',
        "招聘邮箱值": 'hr@pj-logistics.com',
        "隐私政策": 'Privacy Policy',
        "投诉与建议文案": 'Our company is committed to becoming a world-class warehousing and logistics service provider, adhering to the business philosophy of prioritizing customers, giving back to society, and caring for employees. We operate in compliance with laws and regulations and promote healthy operations. If you discover any internal employee engaging in corrupt activities, bribery, or other violations of laws and regulations, or if your legitimate rights and interests are harmed, please feel free to file a complaint or report. We will protect your personal information and provide you with a satisfactory response. Please be sure to leave your real contact information and provide relevant evidence. We will not investigate anonymous reports or unsigned letters.',
        '首页标题': 'Providing order fulfillment and third-party logistics services for global brands and retailers.',
        '首页头部介绍': "Parceljet is a professional cross-border logistics company dedicated to providing high-quality, secure, and efficient cross-border transportation services to our global customers. Through our worldwide warehousing network, we use advanced technology and operational expertise to optimize your order fulfillment, so you can focus on growing your business. Our goal is to deliver your brand to the world and aid your business's robust growth by providing top-tier third-party logistics (3PL) services.",
        "头程": "First leg",
        "空运": "Air freight",
        "海运": "Sea freight",
        "报关": "Customs declaration",
        "清关": "Customs clearance",
        "海外仓储": "Overseas warehousing",
        "尾程物流": "Last-mile logistics",
        "卡派服务": "Dispatch services",
        "头程文本": "One-stop service for cross-border e-commerce brands, global sales planning, unified domestic and international interface; WMS, TMS, and direct OMS management, with ongoing upgrades for a digital logistics experience.",
        "空运文本": "Order making, pick-up, booking, customs, negotiation, transit supervision, temporary storage, distribution, freight insurance purchasing, airport ground coordination, real-time air cargo tracking.",
        "海运文本": "FCL/LCL shipping, refrigerated goods, hazardous materials, chartering service (bulk ship - EU/US), and custom transport solutions.",
        "报关文本": "Professional customs team offers document preparation, classification, duty calculation, and compliance advice for a one-stop solution.",
        "清关文本": "Expert team provides comprehensive customs agency services including pre-shipment documentation, classification and coding, warehousing and logistics, and compliance advice.",
        "海外仓储文本": "160,000㎡ owned warehousing, core export lines to EU/US/JP, leading reverse logistics, managed by local overseas teams, strict warehousing and distribution standards.",
        "尾程物流文本": "Logistics services covering NA, EU, KR/JP, AUS, expanding to emerging markets (SEA & Africa).",
        "卡派服务文本": "30+ vehicles, delivery across 12 countries/regions, efficient, cost-effective, safe, and convenient services to achieve business goals and competitive edge.",
    },
    "de": {
        "首页": "Startseite",
        "公司简介": "Firmenprofil",
        "物流服务范围": "LSS",
        "服务团队": "Service-Team",
        "发展历程": "FL",
        "愿景使命价值观": "Vision,Mission,Werte",
        "我们的优势服务": "OV",
        "我们的服务改善": "OSI",
        "团队分布": "Teamverteilung",
        "合作伙伴招商": "Partner",
        "物流服务产品": "Logistikserviceprodukte",
        "运营团队": "Betriebsteam",
        "海外仓": "Auslandslager",
        "B2BB2C": "B2B/B2C",
        "逆向物流": "Reverse Logistik",
        "设施分布": "Anlagenverteilung",
        "投诉与建议": "Vorschlag",
        "QA": "Q&A",
        "总经办信箱": "Geschäftsführer-E-Mail",
        "联系我们": "Kontaktiere uns",
        "客户登录": "Anmelden",
        "请输入单号": "Bitte geben Sie die Nummer ein",
        "跟踪": "Verfolgung",
        "诚邀全球优质物流企业": 'Arbeiten Sie mit uns zusammen',
        "劳务派遣人才合作": 'Treten Sie bei ParcelJet bei',
        "请输入投诉与建议": 'Bitte geben Sie Beschwerden und Vorschläge ein',
        "提交建议": 'Vorschlag einreichen',
        "请输入您的姓名": 'Bitte geben Sie Ihren Namen ein',
        "请输入您的手机号": 'Bitte geben Sie Ihre Handynummer ein',
        "请输入投诉与建议信息": 'Bitte geben Sie die Beschwerde- und Vorschlagsinformationen ein',
        "提交成功": 'Erfolgreich eingereicht',
        "请输入联系信息及投诉与建议信息": 'Bitte geben Sie Ihre Kontaktdaten sowie Beschwerde- und Vorschlagsinformationen ein',
        "请输入客户投诉号": 'Bitte geben Sie die Kundenbeschwerdenummer ein',
        "请选择投诉项": 'Bitte wählen Sie die Beschwerde aus',
        "提交失败请联系管理员": 'Fehler beim Einreichen. Bitte kontaktieren Sie den Administrator',
        "请输入问题": 'Bitte geben Sie das Problem ein',
        "请输入留言信息": 'Bitte geben Sie eine Nachricht ein',
        "请输入联系信息及留言信息": 'Bitte geben Sie Ihre Kontaktdaten und Ihre Nachricht ein',
        "用户登录": 'Benutzer',
        "请输入帐号": 'Bitte geben Sie Ihren Benutzernamen ein',
        "请输入密码": 'Bitte geben Sie Ihr Passwort ein',
        "前往登录": 'Zum Login gehen',
        "服务动态及新闻": 'Nachrichten',
        "物流查询": 'Logistikverfolgung',
        "查询": 'Anfrage',
        "单号": 'Sendungsnummer',
        "轨迹发生地点": 'Ort',
        "暂无数据": 'Keine Daten verfügbar',
        "暂无轨迹数据可联系小派查询": 'No tracking data available, please contact Xiaopai for inquiries.',
        "合作电话值": '+49 176 1011 9393',
        "合作邮箱值": 'tony.luo@pj-logistics.com',
        "招聘电话值": '+49 157 5177 3650',
        "招聘邮箱值": 'jin.li@pj-logistics.com',
        "隐私政策": 'Datenschutzerklärung',
        "投诉与建议文案": 'Unser Unternehmen ist bestrebt, ein weltweit führender Anbieter von Lager- und Logistikdienstleistungen zu werden. Wir halten an der Geschäftsphilosophie fest, Kunden an erster Stelle zu setzen, der Gesellschaft etwas zurückzugeben und uns um unsere Mitarbeiter zu kümmern. Wir operieren in Übereinstimmung mit den Gesetzen und Vorschriften und fördern einen gesunden Betrieb. Wenn Sie feststellen, dass ein interner Mitarbeiter unseres Unternehmens an Korruption, Bestechung oder anderen Verstößen gegen Gesetze und Vorschriften beteiligt ist oder wenn Ihre legitimen Rechte und Interessen verletzt werden, zögern Sie bitte nicht, eine Beschwerde oder Anzeige einzureichen. Wir werden Ihre persönlichen Informationen schützen und Ihnen eine zufriedenstellende Antwort geben. Bitte hinterlassen Sie unbedingt Ihre echten Kontaktdaten und stellen Sie relevante Beweise zur Verfügung. Anonyme Berichte oder unsignierte Briefe werden von uns nicht untersucht.',
        '首页标题': 'Some error occurred. Please try again or contact the support team in our communities.',
        '首页头部介绍': 'Parceljet ist ein professionelles Unternehmen für grenzüberschreitende Logistik, das sich darauf konzentriert, unseren globalen Kunden qualitativ hochwertige, sichere und effiziente grenzüberschreitende Transportdienstleistungen zu bieten. Mit unserem globalen Lagerhausnetzwerk optimieren wir Ihre Auftragsabwicklung mit fortschrittlichen Technologien und betrieblicher Expertise, so dass Sie sich auf das Wachstum Ihres Geschäfts konzentrieren können. Unser Ziel ist es, Ihre Marke durch erstklassige Third-Party-Logistik (3PL) Dienstleistungen weltweit zu verbreiten und das robuste Wachstum Ihres Geschäfts zu unterstützen.',
        "头程": "Erste Strecke",
        "空运": "Luftfracht",
        "海运": "Seefracht",
        "报关": "Zollabfertigung",
        "清关": "Zollfreigabe",
        "海外仓储": "Überseelagerung",
        "尾程物流": "Endzustellung",
        "卡派服务": "Kurierdienste",
        "头程文本": "One-Stop-Service für globalen Verkauf, inländisch & international vereinheitlichte Schnittstellen, präzise und maßgeschneiderte Antworten; einheitliches WMS & TMS, OMS Direktverwaltung, digitale Logistik-Erfahrungen.",
        "空运文本": "Auftragserstellung, Abholung, Buchung, Zoll, Verhandlung, Überwachungstransport, temporäre Lagerung, Verteilung, Frachtversicherung, Flughafenbodenkoordination, Echtzeit-Luftfrachttracking.",
        "海运文本": "FCL/LCL Transport, Kühltransport für Lebensmittel, Gefahrguttransport, Charterdienste (Bulk - EU/US), maßgeschneiderte Transportlösungen.",
        "报关文本": "Professionelles Zollteam bietet Dokumentenvorbereitung, Klassifizierung, Steuer- und Zollberechnung, Complianceberatung für eine One-Stop-Lösung.",
        "清关文本": "Experten für Zollabwicklung bieten Vorab-Dokumentenvorbereitung, Klassifizierung, Lagerung, Logistik und Compliance-Beratung.",
        "海外仓储文本": "160.000㎡ eigene Lagerressourcen, Kernexportlinien nach EU/US/JP, führende Reverse-Logistik, Überseemanagement, strenge Lager- und Verteilerstandards.",
        "尾程物流文本": "Logistikdienstleistungen in NA, EU, KR/JP, AUS, Erweiterung in Schwellenländer (SEA & Afrika).",
        "卡派服务文本": "30+ Fahrzeuge, Liefernetz in 12 Ländern/Regionen, effizient, kosteneffektiv, sicher, bequem, zur Unterstützung von Geschäftszielen und Wettbewerbsfähigkeit.",
    },
    "fr": {
        "首页": "Accueil",
        "公司简介": "Présentation",
        "物流服务范围": "Gamme de services",
        "服务团队": "Équipe de service",
        "发展历程": "Parcours",
        "愿景使命价值观": "Vision, mission, valeurs",
        "我们的优势服务": "Avantage",
        "我们的服务改善": "Amélioration du service",
        "团队分布": "Répartition de l'équipe",
        "合作伙伴招商": "Partenaire",
        "物流服务产品": "Produit de service",
        "运营团队": "Équipe opérationnelle",
        "海外仓": "Entrepôt à l'étranger",
        "B2BB2C": "B2B/B2C",
        "逆向物流": "Logistique inversée",
        "设施分布": "Répartition des installations",
        "投诉与建议": "Conseil",
        "QA": "Q&A",
        "总经办信箱": "Boîte aux lettres générale",
        "联系我们": "Contactez-nous",
        "客户登录": "Connexion",
        "请输入单号": "Veuillez saisir le numéro de commande",
        "跟踪": "Suivi",
        "诚邀全球优质物流企业": 'Invitation aux entreprises de logistique de qualité du monde entier',
        "劳务派遣人才合作": 'Collaboration en matière de talents',
        "请输入投诉与建议": 'Veuillez saisir vos plaintes et suggestions',
        "提交建议": 'Soumettre une suggestion',
        "请输入您的姓名": 'Veuillez saisir votre nom',
        "请输入您的手机号": 'Veuillez saisir votre numéro de téléphone portable',
        "请输入投诉与建议信息": 'Veuillez saisir vos plaintes et suggestions',
        "提交成功": 'Soumission réussie',
        "请输入联系信息及投诉与建议信息": 'Veuillez saisir vos coordonnées ainsi que vos plaintes et suggestions',
        "请输入客户投诉号": 'Veuillez saisir le numéro de réclamation du client',
        "请选择投诉项": 'Veuillez sélectionner le motif de la réclamation',
        "提交失败请联系管理员": "Échec de la soumission, veuillez contacter l'administrateur",
        "请输入问题": 'Veuillez saisir votre problème',
        "请输入留言信息": 'Veuillez saisir votre message',
        "请输入联系信息及留言信息": 'Veuillez fournir vos coordonnées et votre message.',
        "用户登录": 'Utilisateur',
        "请输入帐号": 'Veuillez saisir le compte',
        "请输入密码": 'Veuillez saisir le mot de passe',
        "前往登录": 'Se connecter',
        "服务动态及新闻": 'Actualités',
        "物流查询": 'Suivi logistique',
        "查询": 'Recherche',
        "单号": 'Numéro de suivi',
        "轨迹发生地点": 'Emplacement',
        "暂无数据": 'Pas de données disponibles',
        "暂无轨迹数据可联系小派查询": "Pas de données de trajectoire disponibles, veuillez contacter Xiao Pai pour plus d'informations.",
        "合作电话值": '0757-81768696',
        "合作邮箱值": 'partner@pj-logistics.com',
        "招聘电话值": '0757-81768696',
        "招聘邮箱值": 'hr@pj-logistics.com',
        "隐私政策": 'Politique de confidentialité',
        "投诉与建议文案": "Notre entreprise s'engage à devenir un fournisseur de services de stockage et de logistique de premier plan au niveau mondial, en adhérant à la philosophie d'entreprise mettant les clients en premier, en redonnant à la société et en prenant soin des employés. Nous respectons les lois et règlements et favorisons une exploitation saine. Si vous découvrez qu'un employé interne de notre entreprise est impliqué dans des activités de corruption, de pots-de-vin ou d'autres violations des lois et règlements, ou si vos droits et intérêts légitimes sont lésés, n'hésitez pas à porter plainte ou à signaler. Nous protégerons vos informations personnelles et vous fournirons une réponse satisfaisante. Veuillez laisser vos coordonnées réelles et fournir des preuves pertinentes. Nous n'enquêterons pas sur les rapports anonymes ou les lettres non signées.",
        '首页标题': "Fournir des services d'exécution des commandes et de logistique tiers pour les marques et les détaillants mondiaux.",
        '首页头部介绍': "Parceljet est une entreprise de logistique transfrontalière professionnelle dédiée à fournir à nos clients mondiaux des services de transport transfrontaliers de haute qualité, sécurisés et efficaces. Grâce à notre réseau d'entrepôts à travers le monde, nous utilisons des technologies avancées et une expertise opérationnelle pour optimiser votre exécution de commandes, vous pouvez donc vous concentrer sur la croissance de votre entreprise. Notre objectif est de transmettre votre marque au monde entier et d'aider la croissance robuste de votre entreprise en fournissant des services logistiques tiers (3PL) de premier plan.",
        "头程": "Première étape",
        "空运": "Fret aérien",
        "海运": "Fret maritime",
        "报关": "Douane",
        "清关": "Dédouanement",
        "海外仓储": "Entreposage à l'étranger",
        "尾程物流": "Logistique du dernier kilomètre",
        "卡派服务": "Services de livraison",
        "头程文本": "Interface unique pour la planification globale de vente, réponse immédiate aux besoins détaillés et personnalisés; services unifiés WMS et TMS, gestion directe OMS, expérience logistique numérique évolutive.",
        "空运文本": "Préparation des commandes, collecte, réservation, douane, négociation, transit, stockage temporaire, distribution, assurance fret, coordination aéroportuaire, suivi et feedback en temps réel.",
        "海运文本": "Transport FCL/LCL, trans. réfrigéré, marchandises dangereuses, affrètement (vrac - EU/US), solutions de transport personnalisées.",
        "报关文本": "Équipe douanière professionnelle offrant préparation de documents, classification, calcul des taxes/droits, conseils en conformité pour une solution complète.",
        "清关文本": "Équipe professionnelle pour le dédouanement offrant préparation et soumission de documents en avance, classification, stockage, logistique, et conseil en conformité.",
        "海外仓储文本": "160 000㎡ de stockage, lignes d'exportation vers EU/US/JP, logistique inverse, gestion par équipes locales, normes strictes de stockage et distribution.",
        "尾程物流文本": "Services logistiques couvrant NA, EU, KR/JP, AUS, expansion vers marchés émergents (SEA & Afrique).",
        "卡派服务文本": "30+ véhicules, distribution dans 12 pays/régions, services efficaces, économiques, sécurisés, pour atteindre les objectifs commerciaux.",
    },
}